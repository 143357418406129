import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Grid, Inner, OnlyPC, OnlySP} from '../components/responsive/'
import H1 from '../components/h1'
import H2 from '../components/h2'
import Banner from '../components/banner'
import Content from '../components/content'
import ContentHTML from '../components/contentHTML'
import Image from '../components/image'
import SpeakerImg from '../components/speakerImg'
import Sponser from '../components/sponser'
import MediaSponser from '../components/mediaSponser'
import ContainerFooter from '../components/containerFooter'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import Entry from '../components/entry'
import Metadata from '../components/metadata'
import './index.css'

export default ({ data }) => (
      <div>
      <Metadata/>
      <Header active='home'/>
      <Banner imgPC={data.topPC.childImageSharp.fixed} imgSP={data.topSP.childImageSharp.fixed}/>
      <Container backgroundColor='#FFF' paddingTop='1em'>
      <H1>
      2019年12月7日、<PCbr/>
      DrupalCamp DEN 2019 Nagoyaを開催。
      </H1>
      <Inner
        size='2em'
        as='h2'
        color='#7f7f7f'
        marginTop='1em'
        marginBottom='1em'
        lineHeight='1.5em'
        center>
      日本のカスタマーエクスペリエンス、<PCbr/>
      デジタルエクスペリエンスを促進させる、<PCbr/>
      オープンソース Drupal（ドルーパル）に関する コミュニティーイベントです。
      </Inner>
      <Entry/>
      <Content margin='2em 0 1em 0'>
      Drupal （ドルーパル）は、デジタルデータプラットフォームとして世界中で支持されており、テスラモーターズ、GE、NASA、ホワイトハウスといったグローバルな大企業・政府機関などで、多くの採用実績があります。<PCbr/><PCbr/>
      Drupal は、単なるウェブサイトの構築・管理にとどまらず、バックエンドのプラットフォームとして利用する事が可能で、デジタルサイネージやスマートウォッチなどのデバイスに表示・通知したり、IoTやAI（人工知能）といったシステムと連携することもできます。<PCbr/><PCbr/>
      オープンソースのDrupalの日本国内のコミュニティーとして、Drupalの知名度の向上、Drupal関連技術者の交流の場として2018年11月17日に第1回　「Drupal Camp DEN  Japan vol.1」を東京羽田で開催し、今回2回目として名古屋での開催となりました。<PCbr/><PCbr/>
      昨年に引き続いて、最先端のテーマを深く語れるキーパーソンを招いての講演会、Drupalを活用している企業、エンジニア、マーケター、ディレクター、デザイナーなど東京・関西方面・名古屋と年１回の情報交換交流の場として今年もセッション、別会場にて会費制による懇親会を準備しております。
      </Content>
      <Inner
        size='2em'
        as='h4'
        color='#0098cb'
        marginTop='2em'
        marginBottom='1em'>
      開催概要
      </Inner>
      <Row>
        <Grid pc='7' sp='12'>
          <Row>
            <Grid pc='3' sp='4'>
              <Inner bold color='#7f7f7f' as='span'>
                開催日時
              </Inner>
            </Grid>
            <Grid pc='9' sp='8'>
              <Inner color='#7f7f7f' as='span'>
                2019年12月7日(土曜日)
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                開催場所
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' color='#7f7f7f'>
                <p>なごのキャンパス （2019年10月28日OPEN）※旧那古野小学校</p>
                <p>
                〒451-0042 愛知県名古屋市西区那古野２丁目１４−１<br/>
                JR/名鉄/地下鉄東山線・桜通線 名古屋駅　桜通口より徒歩8分
                </p>
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                参加人数
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' color='#7f7f7f'>
                70名
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                参加費用
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' color='#7f7f7f'>
                無料
              </Inner>
            </Grid>
            <Grid pc='3' sp='4' as='span'>
              <Inner bold marginTop='1em' color='#7f7f7f'>
                主催
              </Inner>
            </Grid>
            <Grid pc='9' sp='8' as='span'>
              <Inner marginTop='1em' marginBottom='1em' color='#7f7f7f'>
                DEN (Drupal Experience Network)<br/>
                実行委員長：小薗井 康志
              </Inner>
            </Grid>
          </Row>
        </Grid>
        <Grid pc='5' sp='12'>

          <OnlyPC>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.2191652506685!2d136.8841094152451!3d35.17608968031509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600376c3a82a0975%3A0xd3c4c17082fc4658!2z44CSNDUxLTAwNDIg5oSb55-l55yM5ZCN5Y-k5bGL5biC6KW_5Yy66YKj5Y-k6YeO77yS5LiB55uu77yR77yU4oiS77yR!5e0!3m2!1sja!2sjp!4v1572872744059!5m2!1sja!2sjp" width="100%" height="450" frameBorder="0" allowFullScreen title='map1'></iframe>
          </OnlyPC>
      
          <OnlySP>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.2191652506685!2d136.8841094152451!3d35.17608968031509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600376c3a82a0975%3A0xd3c4c17082fc4658!2z44CSNDUxLTAwNDIg5oSb55-l55yM5ZCN5Y-k5bGL5biC6KW_5Yy66YKj5Y-k6YeO77yS5LiB55uu77yR77yU4oiS77yR!5e0!3m2!1sja!2sjp!4v1572872744059!5m2!1sja!2sjp" width="100%" height="250" frameBorder="0" allowFullScreen title='map2'></iframe>
          </OnlySP>

        </Grid>
      </Row>
      <Inner
        size='2em'
        as='h4'
        color='#0098cb'
        marginTop='1em'
        marginBottom='1em'>
      参加対象
      </Inner>
      <Grid pc='6' sp='12'>
        <Content>
        所属：一般ユーザー、企業、Webエージェンシー、フリーランス<br/>
        職種：マーケター、デザイナー、ディレクター、エンジニア、その他<br/>
        レベル：Drupal初心者から上級者
        </Content>
      </Grid>
      <H2>
      KEYNOTE SPEAKER
      </H2>
      <Row>
        <Grid pc='3' sp='8' >
          <SpeakerImg fluid={data.keyspeaker.childImageSharp.fluid}/>
        </Grid>
        <Grid pc='0' sp='12'>
        <OnlySP>
        <Inner margin='1em'>
        </Inner>
        </OnlySP>
        </Grid>
        <Grid pc='9' sp='12'>
          <Inner size='2em' color='#7f7f7f'>
            {data.camp2019.rest.contents.field_keynote_speaker_name}
          </Inner>
          <Content>
            {data.camp2019.rest.contents.field_keynote_speaker_title}
          </Content>
          <ContentHTML>
            <div dangerouslySetInnerHTML={{__html: data.camp2019.rest.contents.field_keynote_speaker_desc}}/>
          </ContentHTML>
        </Grid>
      </Row>
      <H2>
      スポンサー
      </H2>
      <Row>
        { data.camp2019.rest.sponsers.map((sponser, key) => {
          return <Grid key={key} pc='4' pcOffset={key % 2 === 0 ? '2' : '0'} sp='12'>
                    <Sponser fluid={data.sponsers.edges.filter(edge => sponser.fileName === edge.node.childImageSharp.fluid.originalName)[0].node.childImageSharp.fluid}
                      url={sponser.field_sponser_url}/>
                  </Grid>
        })}
      </Row>

          <Content>
          スポンサー様受け付けております。<br/>
          【スポンサー概要】<br/>
          １．スポンサー費：3万円（税別）<br/>
          ２．スポンサー特典<br/>
          セッション登壇者のスピーカー枠<br/>
          イベントサイトへの貴社バナー掲載<br/>
          ※お問合せ、お申込みは、事務局岩田　「den_member@den-japan.org」　迄お願いいたします。
          </Content>

      <H2>
      メディアスポンサー
      </H2>

        <Content>
        メディアスポンサー様受け付けております。<br/>
        ※お問合せ、お申込みは、事務局岩田　「den_member@den-japan.org」　迄お願いいたします。
        </Content>

      <H2>
      ビデオ視聴
      </H2>
      <Row>
        <Grid pc='3' sp='12'>
          <Image fluid={data.video.childImageSharp.fluid}/>
        </Grid>
        <Grid pc='8' pcOffset='0.5' sp='12'>
          <ContentHTML>
            <div dangerouslySetInnerHTML={{__html: data.camp2019.rest.contents.field_video_description}}/>
          </ContentHTML>
        </Grid>
      </Row>
      <H2>
      スケジュール
      </H2>
      <ContentHTML>
        <div dangerouslySetInnerHTML={{__html: data.camp2019.rest.contents.field_schedule}}/>
      </ContentHTML>
      <ContainerFooter/>
      </Container>
      <Footer/>
      </div>
)

export const query = graphql`
  query {
    topPC:file(relativePath: { eq: "Drupal_camp_Banner.png" }) {
      childImageSharp {
        fixed(width: 1140, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    topSP:file(relativePath: { eq: "Drupal_camp_Banner.png" }) {
      childImageSharp {
        fixed(height: 300, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    keyspeaker:file(relativePath: { eq: "keyspeaker.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    video:file(relativePath: { eq: "TOP1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    map:file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    atit400:file(relativePath: { eq: "atmarkit_w400.png" }) {
      childImageSharp {
        fixed(width: 400, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    atit300:file(relativePath: { eq: "atmarkit_w400.png" }) {
      childImageSharp {
        fixed(width: 300, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    sponsers:allFile(filter:{relativePath:{regex:"/^sponser/"}}){
      edges{
        node{
          childImageSharp {
            fluid{
              ...GatsbyImageSharpFluid_noBase64
              originalName
            }
          }
        }
      }
    }
    camp2019{
      rest{
        contents{
          field_keynote_speaker_title,
          field_keynote_speaker_name,
          field_keynote_speaker_desc,
          field_schedule,
          field_video_description
        }
        sponsers {
          field_sponser_url
          field_sponser_logo
          field_sponser_number
          fileName
        }
      }
    }
  }
`


const PCbr = styled.br`
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
